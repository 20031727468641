
































































































































@use "@material/button";

$color_dark: #000010;
$back_grey: #CDD1DF;

.event-buttons {
  display: flex;
  justify-content: center;
  margin: 20px 0 50px;
  width: 100%;}

.mdc-button {
  @include button.ink-color($color_dark);
  @include button.container-fill-color($back_grey);
  @include button.horizontal-padding(0px);
  @include button.density(0);
}
.mdc-button {
  margin: 10px 5px;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.25px;}

.mdc-button .mdc-button__ripple,
.mdc-button {
  border-radius: 20px;}

.mdc-button__label {
  margin: 0 45px;}
