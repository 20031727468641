




















































































































































@use "@material/button";
$color-white: #ffffff;
.accordion * {
  color: var(--color-text-primary);
}
.mdc-button {
  @include button.horizontal-padding(16%);
  @include button.shape-radius(50%, 0, 0);
  letter-spacing: 1.25px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}
.sector-data {
  margin: 20px 0;
  box-shadow:  0px 1px 3px rgba(0, 0, 0, 0.2);
  background-color: var(--color-surface);
  border-radius: 20px;
}
.sector-title {
  padding: 34px 34px 25px;
  margin-top: 0;
  border-radius: 20px 20px 0 0;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  line-height: 16px;
  color: var(--color-text-primary);
  background-color: var(--color-active-surface);
}
.sector-text {
  margin: 0;
  padding-left: 40px;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 24px;}
.text-bold {
  font-weight: bold;}
ul li ol li {
  list-style: decimal;
  padding: 0;}
.accordion-content {
  background-color: #F3F4F7;}
.text-margin-bold {
  font-weight: bold;
  margin-left: 19px;}
.accordioin-content-align {
  display: flex;
  flex-direction: column;}
.map-button-center {
  text-align: center;}
.chart-index {
  padding: 19px;
}
@media (max-width: 839px) {
  .sector-data {
    width: 100%;}
  }
@media (min-width: 840px){
  .sector-data {
    width: 30%;}
}
@media (min-width: 1280px){
  .sector-data {
    width: 30%;}
}
.sector-text-rend{
  margin-left: 10px;
}
