












































































































































































  @use "@material/button";

  $color_dark: #000010;
  $back_grey: #CDD1DF;

  .event-buttons {
    margin: 20px 0 50px;
    width: 100%;
    text-align: center;}

  .mdc-button {
    @include button.ink-color($color_dark);
    @include button.container-fill-color($back_grey);
    @include button.horizontal-padding(0px);
    @include button.density(0);
  }
  .mdc-button {
    margin: 10px 5px;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.25px;}

  .mdc-button .mdc-button__ripple,
  .mdc-button {
    border-radius: 20px;}

  .mdc-button__label {
    margin: 0 45px;}


.chart-index {
  position: relative;
}

highcharts {
  filter: invert(1) hue-rotate(180deg) brightness(0.8);
}

.directionality-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  mix-blend-mode: difference; /* Cambia el blanco al negro */
  z-index: 2;
  pointer-events: none; /* Para que no interfiera con interacciones */
}
