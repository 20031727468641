
























































































































































html{
  overflow-y: scroll;
}

#directionality-component {
  margin-top: 50px;
  margin-left: 50px;
  margin-bottom: 50px;
  padding: 0;
  color: var(--color-text-primary);
}

#directionality {
  background: var(--color-background);
}

$color_dark: #000010;
$back_grey: #cdd1df;

#directionality-mobile-wrapper{
  overflow-x: hidden;
}

.directionality-title {
  margin: 0;
  padding: 60px 40px 40px;
  color: $color_dark;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}

.sector-content {
margin: 40px 0;
display: flex;
flex-wrap: wrap;
flex: 1 1 auto;
align-items: flex-start;
justify-content: space-between;}

/* Add these new styles for loading transitions */
.loading-hidden {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.loading-visible {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

/* Make the loading indicator cover the full screen */
.full-screen-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: var(--color-background, #fff);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Prevent scrolling during loading */
body.loading-active {
  overflow: hidden;
}
