






















































































































@use "@material/checkbox"; 
@use "@material/icon-button"; 
@use "@material/data-table/data-table";

@include checkbox.core-styles;
@include icon-button.core-styles;
@include data-table.core-styles;
@include data-table.theme-baseline;

$color_dark: var(--color-text-primary);
$back_white: var(--color-surface);
$back_light_grey: var(--color-muted);
$highlight_color: var(--color-active-surface); // Replace with your desired hover color

.mdc-data-table__header-cell {
  text-transform: uppercase;
  background-color: $back_light_grey;}
.mdc-data-table__header-cell,
.mdc-data-table__cell{
  color: $color_dark;
  font-size: 12px;
  letter-spacing: 1px;
  text-align: center;}
.mdc-data-table__row {
  background-color: $back_white;

  &:hover {
    background-color: var(--color-background) !important;
    cursor: pointer;
  }
}
.index-content {
  display: flex;
  justify-content: center;
  flex-direction: column;}
p {
  margin: 0;}
.mdc-data-table {
  margin-bottom: 40px;
  width: 100%;}
.options {
  cursor: pointer;
}
