



































































.accordion__item {
  cursor: pointer;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

.accordion__trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 10px 10px 0;
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion__trigger_active {
    color: #00ADD9;
    margin-bottom: 16px;
}
.accordion__content {
    background-color: #F3F4F7;
    padding: 14px 0;
}
.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
ul{
  margin: 0;
  padding: 0;
}
#alarms{
  .accordion__content{
    background-color: transparent !important;
    height: 40vh;
    overflow-y: scroll;
    margin-bottom: 20px;
  }
}
